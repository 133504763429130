import { getCookie, setCookie, TWO_YEARS_IN_S } from './cookies'

import type { ColorMode, createLocalStorageManager } from '@chakra-ui/react'

const COLOR_MODE_KEY = 'color-mode'
export const colorModeManager: ReturnType<typeof createLocalStorageManager> = {
  type: 'cookie',
  ssr: false,
  get() {
    return getCookie(COLOR_MODE_KEY) as ColorMode | undefined
  },
  set(value: ColorMode): void {
    setCookie(COLOR_MODE_KEY, value, TWO_YEARS_IN_S)
  },
}
