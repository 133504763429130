import Plausible from 'plausible-tracker'

const { trackPageview, trackEvent } = Plausible({
  domain: window.location.hostname,
})

export function stopAnalyticsTracking(): void {
  window.localStorage.setItem('plausible_ignore', 'true')
}
export function startAnalyticsTracking(): void {
  window.localStorage.removeItem('plausible_ignore')
}

export function logEvent(
  eventName: string,
  eventParams?: Record<string, any>
): void {
  trackEvent(eventName, {
    props: eventParams,
  })
}

export { trackPageview }
