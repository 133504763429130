export const isMaintenance = import.meta.env.REACT_APP_MAINTENANCE === 'true'

export const isGoogleDown = import.meta.env.REACT_APP_GOOGLE_DOWN === 'true'

export function isReactNativeWebView(): boolean {
  // @ts-expect-error Property 'isReactNativeWebView' does not exist on type 'Window & typeof globalThis'. TS2339
  if (window.isReactNativeWebView != null) {
    return true
  }
  // @ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'. TS2339
  return typeof window?.ReactNativeWebView?.postMessage === 'function'
}
