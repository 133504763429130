// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react'

import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import createDebug from 'debug'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const UPDATE_CHECK_INTERVAL = 30 * 60 * 1000

const debug = createDebug('captego:studio:serviceworker')

export function SWReloadPrompt() {
  const { t } = useTranslation('common')
  const toast = useToast()

  const { colorMode } = useColorMode()
  const toastBg = useColorModeValue('blue.100', 'blue.700')

  const buildDate = '__DATE__'
  const reloadSW: string = '__RELOAD_SW__'

  const {
    offlineReady: [_, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      if (reloadSW === 'true') {
        if (r != null) {
          setInterval(() => {
            debug('Checking for update')
            void r.update()
          }, UPDATE_CHECK_INTERVAL)
        }
      } else {
        debug('Registered', { r })
      }
    },
    onRegisterError(error) {
      console.error('Registration error', error)
    },
  })

  const cancelUpdate = useCallback(() => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }, [setNeedRefresh, setOfflineReady])

  useEffect(() => {
    const toastId = `update-${buildDate}`
    if (needRefresh && !toast.isActive(toastId)) {
      toast({
        position: 'top-right',
        duration: null,
        variant: 'left-accent',
        id: toastId,
        render: () => (
          <Alert
            status="info"
            bgColor={toastBg}
            borderInlineStartWidth={4}
            borderInlineStartColor="blue.500"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon />
            <Text>{t('serviceWorker.update.prompt')}</Text>
            <Button
              marginLeft={3}
              marginRight={3}
              textTransform="uppercase"
              colorScheme="blue"
              variant="ghost"
              onClick={async () => {
                await updateServiceWorker(true)
                toast.closeAll()
              }}
            >
              {t('serviceWorker.update.refresh')}
            </Button>

            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={() => {
                toast.closeAll()
              }}
            />
          </Alert>
        ),
      })
    }
  }, [
    cancelUpdate,
    colorMode,
    needRefresh,
    t,
    toast,
    toastBg,
    updateServiceWorker,
  ])
  return null
}
