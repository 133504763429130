import { Center, CircularProgress, Flex } from '@chakra-ui/react'
import { Fragment } from 'react'

import { ColorModeEnforcer } from '../../pages/reports/edit/components/ColorModeEnforcer'

import type { ReactElement } from 'react'

export interface LoaderProps {
  /**
   * Use initial color instead of the current one
   * (used in the Report, where Light color mode is forced)
   */
  initialColor?: boolean
  tmp?: string
}

export function Loader({ initialColor = false }: LoaderProps): ReactElement {
  const MaybeColorEnforcer = initialColor ? ColorModeEnforcer : Fragment
  return (
    <MaybeColorEnforcer>
      <Flex minH="100vh" h="100vh" maxH="100vh" flexDirection="column">
        <Center w="100%" h="100%">
          <CircularProgress isIndeterminate color="brand.300" />
        </Center>
      </Flex>
    </MaybeColorEnforcer>
  )
}
