import { theme } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'

import type { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction } from '@chakra-ui/styled-system'

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const colorScheme: string = props.colorScheme

  const origBaseStyle = theme.components.Drawer.baseStyle?.(props) ?? {
    header: {},
    closeButton: {},
  }

  if (colorScheme === 'gray') {
    return {
      header: {
        ...origBaseStyle.header,
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      closeButton: {
        ...origBaseStyle.closeButton,
        top: '15px',
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
    }
  }

  return {
    header: {
      ...origBaseStyle.header,
      bg: mode(`${colorScheme}.500`, `${colorScheme}.200`)(props),
      color: mode('white', `gray.800`)(props),
    },
    closeButton: {
      ...origBaseStyle.closeButton,
      bg: mode(`${colorScheme}.500`, `${colorScheme}.200`)(props),
      top: '15px',
      color: mode('white', `gray.800`)(props),
    },
  }
}

export default {
  baseStyle,
  variants: {
    'allow-scroll': {
      dialogContainer: {
        height: 'unset',
        top: 'unset',
        bottom: '0',
      },
      dialog: {
        boxShadow: 'dark-lg',
      },
    },
  },
}
