const Popover = {
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
      },
      content: {
        width: 'fit-content',
      },
    },
  },
}

export default Popover
