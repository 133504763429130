import { theme } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'

import type { SystemStyleFunction } from '@chakra-ui/styled-system'

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const solidVariant: any = theme.components.Button.variants?.solid(props)

  if (c === 'brand') {
    return {
      ...solidVariant,
      bg: 'brand.300',
      color: mode('white', 'gray.700')(props),
      _hover: {
        ...solidVariant._hover,
        bg: 'brand.400',
        color: mode('white', 'gray.700')(props),
      },
    }
  }

  return solidVariant as ReturnType<SystemStyleFunction>
}

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const ghostVariant = theme.components.Button.variants?.ghost(props) ?? {}

  if (c === 'brand') {
    return {
      ...ghostVariant,
      color: 'brand.300',
      _hover: {
        bg: 'brandAlpha.300',
      },
      _active: {
        bg: `brandAlpha.400`,
      },
    }
  }

  return ghostVariant
}

const variantLight: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const ghostVariant = theme.components.Button.variants?.ghost(props) ?? {}

  if (c === 'brand') {
    return {
      ...ghostVariant,
      color: 'brand.300',
      bg: 'brandAlpha.300',
      _hover: {
        color: 'brand.500',
        bg: 'brandAlpha.500',
      },
      _active: {
        color: 'brand.600',
        bg: `brandAlpha.600`,
      },
    }
  }

  return ghostVariant
}

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const outlineVariant = theme.components.Button.variants?.outline(props) ?? {}

  if (c === 'brand') {
    return {
      ...outlineVariant,
      borderColor: 'brand.300',
      ...variantGhost(props),
    }
  }

  return outlineVariant
}

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const linkVariant = theme.components.Button.variants?.link(props) ?? {}

  if (c === 'brand') {
    return {
      ...linkVariant,
      color: 'brand.300',
    }
  }

  return linkVariant
}
const variantLinkLeft: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const linkVariant = theme.components.Button.variants?.link(props) ?? {}

  if (c === 'brand') {
    return {
      ...linkVariant,
      color: 'brand.300',
      justifyContent: 'flex-start',
    }
  }

  return {
    ...linkVariant,
    justifyContent: 'flex-start',
  }
}

/** Small link variant, only used for "Forgotten password?" button */
const variantSmallLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const linkVariant = theme.components.Button.variants?.link(props) ?? {}

  if (c === 'brand') {
    return {
      ...linkVariant,
      color: 'brand.300',
      fontWeight: 'semibold',
      fontSize: 'sm',
      lineHeight: 6,
      mb: 2,
    }
  }
  return {
    ...linkVariant,
    fontWeight: 'semibold',
    fontSize: 'sm',
    lineHeight: 6,
    mb: 2,
  }
}

const Button = {
  variants: {
    solid: variantSolid,
    ghost: variantGhost,
    light: variantLight,
    outline: variantOutline,
    link: variantLink,
    linkLeft: variantLinkLeft,
    smallLink: variantSmallLink,
  },
}

export default Button
