import createDebug from 'debug'

try {
  // @ts-expect-error hacking the color scheme to https://www.nordtheme.com/
  createDebug.colors = [
    '#bf616a',
    '#d08770',
    '#ebcb8b',
    '#a3be8c',
    '#b48ead',

    '#8fbcbb',
    '#88c0d0',
    '#81a1c1',
    '#5e81ac',

    // '#2e3440',
    // '#3b4252',
    '#434c5e',
    '#4c566a',
  ]
} catch {}

try {
  createDebug.formatters.J = (v: unknown) => {
    try {
      return JSON.stringify(v, null, 2)
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `[UnexpectedJSONParseError]: ${(error as Error).message}`
    }
  }
} catch {}

try {
  localStorage.debug = '*'
} catch {}

export {}
