import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/replay'
import { BrowserTracing } from '@sentry/tracing'

if (import.meta.env.PROD) {
  Sentry.init({
    environment: window.location.hostname,
    debug: false,
    dsn: 'https://c69cab156d224032a7f9d79f088675f0@o234970.ingest.sentry.io/5947350',
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'studio@0.0.0',
    integrations: [new BrowserTracing(), new Replay()],
    ignoreErrors: [
      'Network request failed',
      'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    ],
  })
}
