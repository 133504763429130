import createDebug from 'debug'
import {
  browserLocalPersistence,
  getAuth as firebaseGetAuth,
  initializeAuth,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signOut as firebaseSignOut,
} from 'firebase/auth'

import { fetchData } from '../gql/fetcher'
import { setTokenCookie } from '../utils/cookies'
import { firebaseApp } from './init'

import type { RefreshCustomTokenMutation } from '../gql/generated'
import type {
  Auth,
  CompleteFn,
  ErrorFn,
  NextOrObserver,
  Unsubscribe,
  User,
  UserCredential,
} from 'firebase/auth'

const debug = createDebug('captego:studio:auth')

const refreshCustomTokenMutation = `mutation refreshCustomToken {
  refreshCustomToken {
    customToken
  }
}
`

export const auth = initializeAuth(firebaseApp, {
  persistence: [browserLocalPersistence],
})

export function getAuth(): Auth {
  return firebaseGetAuth(firebaseApp)
}

const refreshTokenInterval: { intervalId: number } = {
  intervalId: 0,
}
export function stopRefreshingIdToken(): void {
  if (refreshTokenInterval.intervalId !== 0) {
    debug('Stopping Firebase customIdToken refresh setInterval()')
    clearInterval(refreshTokenInterval.intervalId)
  }
}
async function refreshIdToken() {
  if (auth.currentUser != null) {
    debug('Refreshing Firebase customIdToken')
    const query = refreshCustomTokenMutation

    if (query != null) {
      const res = (await fetchData(query, {})()) as RefreshCustomTokenMutation
      if (res?.refreshCustomToken?.customToken != null) {
        setTokenCookie(res.refreshCustomToken.customToken)
      }
    }
  }
}
export async function startRefreshingIdToken(): Promise<void> {
  stopRefreshingIdToken()
  debug('Starting Firebase customIdToken refresh setInterval()')
  await refreshIdToken()
  refreshTokenInterval.intervalId = window.setInterval(
    async () => {
      await refreshIdToken()
    },
    /** every 58 minutes */
    58 * 60 * 1000
  )
}
export async function signInWithCustomToken(
  customToken: string
): Promise<UserCredential> {
  return await firebaseSignInWithCustomToken(auth, customToken)
}

export async function signOut(): Promise<void> {
  stopRefreshingIdToken()
  await firebaseSignOut(auth)
}

export function onAuthStateChanged(
  nextOrObserver: NextOrObserver<User>,
  error?: ErrorFn,
  completed?: CompleteFn
): Unsubscribe {
  return firebaseOnAuthStateChanged(auth, nextOrObserver, error, completed)
}
