import { createContext, useState } from 'react'

import type {
  ReactElement,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react'

export interface FetchParamsContextType {
  headers: Record<string, string>
  setHeaders: Dispatch<SetStateAction<Record<string, string>>>
}

export const FetchParamsContext = createContext<FetchParamsContextType>({
  headers: {},
  setHeaders: () => {},
})

export function FetchParamsProvider({
  children,
}: PropsWithChildren<{}>): ReactElement | null {
  const [headers, setHeaders] = useState<FetchParamsContextType['headers']>({})

  return (
    <FetchParamsContext.Provider
      value={{
        headers,
        setHeaders,
      }}
    >
      {children}
    </FetchParamsContext.Provider>
  )
}
