import { atom } from 'recoil'

import { PREFIX } from './constants'

import type { ColorMode } from '@chakra-ui/react'

export const colorModeState = atom<ColorMode>({
  key: `${PREFIX}/colorMode`,
  default: 'light',
})
