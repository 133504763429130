import { useContext } from 'react'

import { auth } from '../firebase/auth'
import i18n from '../i18n'
import { FetchParamsContext } from './FetchParamsContext'

const queryNameRegExp = /\s*(query|mutation) ([^({ ]*)/

function getQueryNameAsParam(query: string): string {
  const match = queryNameRegExp.exec(query)
  if (Array.isArray(match) && match[2] != null) {
    return `?${match[2]}`
  }

  return ''
}

export const useFetchData = <TData, TVariables>(
  query: string
): (() => Promise<TData>) => {
  const { headers } = useContext(FetchParamsContext)
  return async (variables?: TVariables) => {
    let idToken
    if (auth.currentUser != null) {
      idToken = await auth.currentUser.getIdToken(true)
    }

    const res = await fetch(
      `${import.meta.env.REACT_APP_GRAPHQL_ENDPOINT}${getQueryNameAsParam(
        query
      )}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Locale': i18n.language,
          ...(idToken != null && idToken !== ''
            ? { Authorization: `Bearer ${idToken}` }
            : {}),
          ...headers,
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    )

    const json = await res.json()

    if (json.errors != null) {
      const { message } = Array.isArray(json.errors)
        ? json.errors[0]
        : { message: 'Error..' }
      throw new Error(message)
    }

    return json.data
  }
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    let idToken
    if (auth.currentUser != null) {
      idToken = await auth.currentUser.getIdToken(true)
    }

    const res = await fetch(
      `${import.meta.env.REACT_APP_GRAPHQL_ENDPOINT}${getQueryNameAsParam(
        query
      )}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Locale': i18n.language,
          ...(idToken != null && idToken !== ''
            ? { Authorization: `Bearer ${idToken}` }
            : {}),
          // notice that the HeaderContext is not used here.
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    )

    const json = await res.json()

    if (json.errors != null) {
      const { message } = Array.isArray(json.errors)
        ? json.errors[0]
        : { message: 'Error..' }
      throw new Error(message)
    }

    return json.data
  }
}
