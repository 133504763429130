import type { Styles } from '@chakra-ui/theme-tools'

const styles: Styles = {
  global: () => ({
    'html, body': {
      fontSize: '16px',
      lineHeight: '1.5',
      // color: { _light: 'gray.700', _dark: 'whiteAlpha.900' },
    },
  }),
}

export default styles
