import { LightMode, DarkMode } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { colorModeState } from '../../_common/recoil/colorMode'

import type { ReactElement } from 'react'

export function ColorModeEnforcer({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const initialColorMode = useRecoilValue(colorModeState)
  const Enforcer = initialColorMode === 'light' ? LightMode : DarkMode

  return <Enforcer>{children}</Enforcer>
}
