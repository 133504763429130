/* eslint-disable @typescript-eslint/explicit-function-return-type */
import i18next, { changeLanguage as i18NextChangeLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocizeBackend from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'

import enUSAuthV2 from './locales/en-US/auth-v2.json'
import enUSAuth from './locales/en-US/auth.json'
import enUSCommon from './locales/en-US/common.json'
import enUSMaintenance from './locales/en-US/maintenance.json'
import enUSPlans from './locales/en-US/plans.json'
import enUSRemoteCapture from './locales/en-US/remotecapture.json'
import enUSReports from './locales/en-US/reports.json'

export const defaultNS = 'common'
export const resources = {
  'en-US': {
    'auth-v2': enUSAuthV2,
    'auth': enUSAuth,
    'common': enUSCommon,
    'maintenance': enUSMaintenance,
    'plans': enUSPlans,
    'remotecapture': enUSRemoteCapture,
    'reports': enUSReports,
  },
} as const
export const fallbackLng = 'en-US'

// Exported at EOF
const languages = [
  { tag: 'da-DK', enName: 'Danish (Denmark)', nativeName: 'Dansk (Danmark)' },
  { tag: 'en-GB', enName: 'English (UK)', nativeName: 'English (UK)' },
  { tag: 'en-US', enName: 'English (US)', nativeName: 'English (US)' },
]

// Exported at EOF
const changeLanguage = async (language: string) => {
  if (language == null) {
    return
  }
  await i18NextChangeLanguage(language)
}

const projectId = import.meta.env.REACT_APP_LOCIZE_PROJECT_ID
const apiKey = import.meta.env.REACT_APP_LOCIZE_API_KEY
const version = 'latest'

// eslint-disable-next-line import/no-named-as-default-member
void i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    load: 'currentOnly',
    supportedLngs: ['en-US', 'en-GB', 'da-DK'],
    defaultNS,
    ns: [
      'auth',
      'auth-v2',
      'common',
      'maintenance',
      'plans',
      'remotecapture',
      'reports',
    ],
    debug: false, // process.env.NODE_ENV !== 'production',
    saveMissing: false, // process.env.NODE_ENV !== 'production',
    cleanCode: true,
    backend: {
      backends: [LocizeBackend, HttpBackend],
      backendOptions: [
        {
          projectId,
          apiKey,
          version,
          referenceLng: 'en-US',
          crossDomain: true,
          withCredentials: true,
        },

        { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      ],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['a', 'br', 'strong', 'i', 'wbr'], // don't convert to <1></1> if simple react elements
      useSuspense: true,
    },
    detection: {
      // order and from where user language' should be detected
      order: ['querystring', 'cookie'],

      // keys or params to lookup language from
      lookupQuerystring: 'locale',
      lookupCookie: 'i18next',

      // cache user language on
      caches: ['cookie'],

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: '.captego.com',

      htmlTag: document.documentElement,

      /** @TODO use getCookieHost() */
      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: {
        domain: '.captego.com',
        sameSite: 'strict',
        secure: true,
        maxAge: 3600,
      },
    },
  })

export default i18next

export { changeLanguage, languages }
