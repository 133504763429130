export const PREFIX = 'reportEditor'

export const BASIC_OPERATORS = {
  '=': 'is',
  '<>': 'is not',
}

export const NO_VALUE_OPERATORS = {
  'empty': 'is empty',
  'not empty': 'is NOT empty',
}

export const CONTAINS_OPERATORS = {
  'contains': 'contains',
  'not contains': 'does NOT contain',
}

export const TEXT_OPERATORS = {
  ...BASIC_OPERATORS,
  ...NO_VALUE_OPERATORS,
  ...CONTAINS_OPERATORS,
}

export const TAG_FILTERS = {
  ...BASIC_OPERATORS,
  'within': 'is within',
  'not within': 'is NOT within',
}

export const ALL_OPERATORS = {
  ...BASIC_OPERATORS,
  ...NO_VALUE_OPERATORS,
  ...TEXT_OPERATORS,
  ...TAG_FILTERS,
}
