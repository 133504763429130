import { theme } from '@chakra-ui/pro-theme'

export default {
  sizes: theme.components.Table.sizes,
  baseStyle: {
    ...theme.components.Table.baseStyle,
    table: {
      ...theme.components.Table.baseStyle.table,
      whiteSpace: 'normal',
    },
  },
  variants: theme.components.Table.variants,
}
