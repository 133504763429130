export function setRequiredActions(_hasRequiredActions: boolean): void {
  window.localStorage.setItem(
    '__requiredActions',
    _hasRequiredActions ? 'true' : ''
  )
}

export function hasRequiredActions(): boolean {
  return window.localStorage.getItem('__requiredActions') === 'true'
}
