const reReports = /\/reports\/(edit|view)\/(.*)$/

export const ADMIN_URLS = [
  '/admindashboard',
  '/projects',
  '/inspections',
  '/templates',
  '/inspections-beta',
  '/accounts',
  '/company/users',
  '/company/contacts',
  '/company',
]

export function redactIDFromURL(url: string) {
  if (reReports.test(url)) {
    return url.replace(reReports, '/reports/$1/_ID_')
  }

  return url
}
