const colors = {
  brand: {
    50: '#dff6f1',
    100: '#afe8d9',
    200: '#78d9c1',
    /* maybe this should be: 100 197 169 ? */
    300: '#32c9a8', // Captego Green
    400: '#00bc95',
    500: '#00ad84',
    600: '#009f77',
    700: '#008e67',
    800: '#007e59',
    900: '#00603c',
  },
  brandAlpha: {
    50: 'rgba(50, 201, 168, 0.04)',
    100: 'rgba(50, 201, 168, 0.06)',
    200: 'rgba(50, 201, 168, 0.08)',
    300: 'rgba(50, 201, 168, 0.16)',
    400: 'rgba(50, 201, 168, 0.24)',
    500: 'rgba(50, 201, 168, 0.36)',
    600: 'rgba(50, 201, 168, 0.48)',
    700: 'rgba(50, 201, 168, 0.64)',
    800: 'rgba(50, 201, 168, 0.80)',
    900: 'rgba(50, 201, 168, 0.92)',
  },
  brandComplement: {
    50: '#fbe4e9',
    100: '#f5bbc9',
    200: '#ee91a6',
    300: '#e66785',
    400: '#df4b6c',
    500: '#d93655',
    600: '#c93253', // Captego Purple
    700: '#b42d4e',
    800: '#9f284b',
    900: '#7a2143',
  },
  brandGray: {
    50: '#e5ecf5',
    100: '#c2d0dc',
    200: '#a0b0bf',
    300: '#7c90a4',
    400: '#637a8f',
    500: '#49647c',
    600: '#3d576c',
    700: '#2f4456',
    800: '#213241',
    900: '#101f2b', // Captego Gray
  },
}

export default colors
