import type { ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  /**
   * System sets initial value.
   */
  initialColorMode: 'system',
  /**
   * App color mode is detached from system color mode changes.
   */
  useSystemColorMode: false,
}

export default config
