import { useState } from 'react'

import { AppRouter } from './AppRouter'
import { LoadingContext } from './LoadingContext'

import type { ReactElement } from 'react'

export function App(): ReactElement {
  const [isLoading, setLoading] = useState<{ isLoading: boolean }>({
    isLoading: false,
  })

  return (
    <LoadingContext.Provider value={[isLoading, setLoading]}>
      <AppRouter />
    </LoadingContext.Provider>
  )
}
