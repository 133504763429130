const baseStyle = {
  fontWeight: 'semibold',
}

const sizes = {
  '2xl': {
    fontSize: ['4xl', null, '5xl'],
    lineHeight: [1.2, null, 1],
    letterSpacing: 'tight',
  },
  'xl': {
    fontSize: ['3xl', null, '4xl'],
    lineHeight: [1.33, null, 1.2],
    letterSpacing: 'tight',
  },
  'lg': {
    fontSize: ['2xl', null, '3xl'],
    lineHeight: [1.33, null, 1.2],
    letterSpacing: 'tight',
  },
  'md': {
    fontSize: 'xl',
    lineHeight: 1.2,
    letterSpacing: 'tight',
  },
  'sm': {
    fontSize: 'md',
    lineHeight: 1.2,
  },
  'xs': {
    fontSize: 'sm',
    lineHeight: 1.2,
  },
}

export default {
  baseStyle,
  sizes,
}
