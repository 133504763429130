import { useState } from 'react'

export const HOST = window.location.hostname

export const TWO_YEARS_IN_S = 60 * 60 * 24 * 365 * 2

/**
 * @see https://stackoverflow.com/a/16061433
 */
export function getCookie(name: string): string {
  const match = `${document.cookie};`.match(new RegExp(`${name}=.*;`))
  if (match != null) {
    return match[0].split(/=|;/)[1]
  }
  return ''
}

export function setCookie(
  name: string,
  value: string,
  maxAge: number = 3600
): void {
  try {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )};domain=${getCookieHost()};max-age=${maxAge};path=/;samesite=strict;secure`
  } catch {}
}

function getTokenCookieName(): string {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `__token_${import.meta.env.REACT_APP_FIREBASE_PROJECT_ID}`
}

function getLogoutCookieName(): string {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `__logout_${import.meta.env.REACT_APP_FIREBASE_PROJECT_ID}`
}

/**
 * @see https://stackoverflow.com/a/16061433
 */
export function getTokenCookie(): string {
  return getCookie(getTokenCookieName())
}

function getCookieHost(): string {
  return HOST.endsWith('.captego.com') ? '.captego.com' : '.web.app'
}

export function setTokenCookie(customToken: string): void {
  setCookie(getTokenCookieName(), customToken)
  setCookie(getLogoutCookieName(), '', 0)
}

export function deleteTokenCookie(): void {
  setCookie(getTokenCookieName(), '', 0)
}

export function setLogoutCookie(): void {
  setCookie(getLogoutCookieName(), 'manage')
}

export function useCookie(name: string, maxAge: number = 3600) {
  const [item, setItem] = useState(() => getCookie(name))

  const updateItem = (value: string) => {
    setItem(value)
    setCookie(name, value, maxAge)
  }

  return [item, updateItem] as const
}
