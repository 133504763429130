import { hasRequiredActions } from '../pages/authentication/utils/requiredActions'
import { getTokenCookie } from './cookies'
import { isMaintenance, isReactNativeWebView } from './globalModes'

let allowRedirectToAdmin = true

export function disableRedirectToAdmin(): void {
  allowRedirectToAdmin = false
}

export function enableRedirectToAdmin(): void {
  allowRedirectToAdmin = true
}

/** @TODO move to a different authenticationPageMode.ts */
export function getAdminURL(path: string): string {
  const searchParams = new URLSearchParams()
  let hasUTMParams = false
  try {
    const url = new URL(document.location.href)
    ;[
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ].forEach((searchParam) => {
      const searchParamValue = url.searchParams.get(searchParam)
      if (searchParamValue != null) {
        hasUTMParams = true
        searchParams.set(searchParam, searchParamValue)
      }
    })
  } catch {}

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${import.meta.env.REACT_APP_ADMIN_URL}/${path}${
    hasUTMParams ? `?${searchParams.toString()}` : ''
  }`
}

export const redirectToStudio =
  import.meta.env.REACT_APP_REDIRECT_TO_STUDIO === 'true'

export function redirectToAdmin(path: string = 'admindashboard'): void {
  if (!isMaintenance && allowRedirectToAdmin && !hasRequiredActions()) {
    if (isReactNativeWebView()) {
      try {
        // @ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'. TS2339
        window?.ReactNativeWebView?.postMessage(getTokenCookie())
        return
      } catch {}
    }
    if (redirectToStudio) {
      document.location.href = '/reports'
    } else {
      document.location.href = getAdminURL(path)
    }
  }
}
